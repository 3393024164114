<template>
  <section class="goods-operation">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="d-flex headSearch" >
        <div class="form-item btn-wrap">
          <el-button
            type="primary"
            style="margin-left: 5px"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onHandleCreate" type="primary">新 增</el-button>
          <el-button @click="onHandleCreateall" type="primary">批量新增</el-button>
        </div>
        <div class="form-item">
          <el-select v-model="formData.buyer_id" filterable clearable   placeholder="选择采购">
            <el-option
              v-for="item of searchData.buyerList"
              :key="item.id"
              :label="item.fullname"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select v-model="formData.supplier_id"  filterable clearable  placeholder="选择供应商">
            <el-option
              v-for="item of searchData.supplierList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select v-model="formData.first_cid"  filterable clearable  placeholder="一级分类">
            <el-option
              v-for="item of searchData.firstCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select v-model="formData.cid"  filterable clearable  placeholder="二级分类">
            <el-option
              v-for="item of searchData.secondCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select v-model="formData.state"   clearable  placeholder="商品状态">
            <el-option
              v-for="item of Object.entries(searchData.productStateList?searchData.productStateList:{})"
              :key="item[0]"
              :label="item[1]"
              :value="item[0]"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select v-model="formData.status"   clearable  placeholder="置顶状态">
            <el-option
              v-for="item of Object.entries(searchData.productSortStatusList?searchData.productSortStatusList:{})"
              :key="item[0]"
              :label="item[1]"
              :value="item[0]"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.product_name_or_sku_code"
            clearable
            placeholder="商品名称/货号"
          >
          </el-input>
        </div>
        <div class="form-item">
          <el-select v-model="formData.source" clearable placeholder="选择位置">
            <el-option
              v-for="item of sourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.logistic_business_id"
            filterable
            clearable
            placeholder="集配中心"
          >
            <el-option
              v-for="item in searchData.businessList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item btn-wrap">
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
          <el-button @click="exportHandle" type="warning">导出</el-button>
        </div>
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @on-change="onHandleRefresh"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 新增弹窗 start -->
    <edit-data-dialog
      ref="refEditDataDialog"
      @on-change="onHandleRefresh"
    ></edit-data-dialog>
    <!-- 新增弹窗 end -->
    <!-- 新增弹窗 start -->
    <edit-dataall-dialog
      ref="refEditDataallDialog"
      @on-change="onHandleRefresh"
    ></edit-dataall-dialog>
    <!-- 新增弹窗 end -->
  </section>
</template>
<script>
import EditDataDialog from "./modules/edit-data-dialog/index.vue";
import EditDataallDialog from "./modules/edit-dataall-dialog/index.vue";
import {
  getProductSortList,
  getsortlistexport,
  postProductsearchdata
} from "@/api/generalControl/config/index";
import TableList from "./modules/table-list/index.vue";
import cloneDeep from "lodash/cloneDeep";
import { GOODS_SOURCE_ENUM } from "./utils/enum/index";
import { downLoadFlowBlob } from "@/utils/tools/base";
export default {
  name: "GeneralGoodsOperation",
  components: {
    EditDataDialog,
    EditDataallDialog,
    TableList,
  },
  data() {
    return {
      loadingData: false,
      disabledExport: false,
      formData: {
        logistic_business_id: "", // 集配中心ID
        product_name_or_sku_code: "", // 商品名称/货号
        source: '', // 位置
        buyer_id: '',
        supplier_id: '',
        first_cid: '',
        cid: '',
        state: '',
        status: '',
        page: 1,
        pageSize: 50,
      },
      searchData: {}, // 查询数据

      formDataClone: {}, // 复制数据，用于初始化
      sourceList: [{ value: 1,
        label: '首页'},{
    value: 2,
    label: '列表页'
  }], // 位置下拉
      logisticsList: [], // 集配中心下拉
      tableData: [], // 列表数据
      total: 0,
    };
  },
  created() {
    this.formDataClone = cloneDeep(this.formData);
    this.initData();
  },
  methods: {
    // 查询条件
    searchHandle() {
      postProductsearchdata().then(res=>{
        this.searchData=res.data
      })
    },

    /* 导出 */
    async exportHandle() {
      this.disabledExport = true;
      this.$message.success("正在导出，请耐心等待");
      try {
        const params = {
          ...this.formData,
        };
        delete params.page;
        delete params.pageSize;
        const res = await getsortlistexport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax getsortlistexport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /**
     * 初始化数据
     */
    initData() {
      this.ajaxGetTableData();
      // this.ajaxGetLogisticsList();
      this.searchHandle()
    },
    /**
     * 获取集配中心列表
     */
    async ajaxGetLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
      } catch (err) {
        console.log("ajax ajaxGetLogisticsList err", err);
      }
    },
    /**
     * 获得列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      try {
        const { data } = await getProductSortList(this.formData);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.error("ajax getProductSortList err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.ajaxGetTableData();
    },
    /**
     * 新增
     */
    onHandleCreate() {
      this.$refs.refEditDataDialog.onInitData();
    },
    /**
     *批量 新增
     */
    onHandleCreateall() {
      this.$refs.refEditDataallDialog.onInitData();
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.formData.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-operation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .fy {
    .el-pagination {
      padding-top: 15px;
      box-sizing: border-box;
    }
  }
  .headSearch {
    flex: 1;
    justify-content: start;
    flex-wrap: wrap;
    gap: 15px;
  }
}
</style>
