var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.titleDialog,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "40%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onHandleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents" },
            [
              _c(
                "el-form",
                {
                  ref: "refForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.formRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "位置：", prop: "source" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "选择位置", clearable: "" },
                          model: {
                            value: _vm.formData.source,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "source", $$v)
                            },
                            expression: "formData.source",
                          },
                        },
                        _vm._l(_vm.sourceList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传文件", prop: "sku_codes_file" } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          { staticStyle: { flex: "1" } },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                staticClass: "upload-demo",
                                attrs: {
                                  action: `${_vm.BASE.PRO1}/uploadFile`,
                                  headers: { token: _vm.token },
                                  accept: ".xlsx, .xls",
                                  "on-success": _vm.successFile,
                                  "on-error": _vm.errorFile,
                                  "before-remove": _vm.beforeRemove,
                                  multiple: "",
                                  limit: 1,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("点击上传")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [_vm._v("只能上传.xlsx, .xls文件")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-right": "50px",
                              "box-sizing": "border-box",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: { click: _vm.openfile },
                              },
                              [_vm._v("下载模版")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onHandleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onHandleSubmit },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }