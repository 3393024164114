/** 商品来源枚举 */
export const GOODS_SOURCE_ENUM = {
  ALL: {
    value: 3,
    label: '全部'
  },
  HOME: {
    value: 1,
    label: '首页'
  },
  LIST: {
    value: 2,
    label: '列表页'
  }
}
