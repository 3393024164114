<template>
  <el-dialog
  v-if="dialogVisible"
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        :model="formData"
        ref="refForm"
        :rules="formRules"
        label-width="100px"
      >
        
        <el-form-item label="位置：" prop="source">
          <el-select v-model="formData.source" placeholder="选择位置" clearable>
            <el-option
              v-for="item of sourceList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传文件" prop="sku_codes_file">
         <div style="display: flex;">
          <div style="flex: 1;"> <el-upload
            class="upload-demo"
            :action="`${BASE.PRO1}/uploadFile`"
            :headers="{'token':token}"
            ref="upload"
            accept=".xlsx, .xls"
            :on-success="successFile"
           :on-error="errorFile"
            :before-remove="beforeRemove"
            multiple
            :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传.xlsx, .xls文件</div>
          </el-upload></div>
         
         <div style="padding-right: 50px;box-sizing: border-box;" > <el-button size="small" type="primary" @click="openfile">下载模版</el-button></div>
         </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { validInteger } from "@/utils/tools/validate";
import { GOODS_SOURCE_ENUM } from "../../utils/enum/index";
import {
  postProductSelectSearch,
  postsortbatchadd,
} from "@/api/generalControl/config/index";
import { BASE } from "@/api";
export default {
  name: "EditDataDialog",
  props: {},
  data() {
    return {
      BASE,
      token: sessionStorage.getItem("token"),
      dialogVisible: false,
      loadingSelect: false,
      titleDialog: "添加商品",
      goodsSelectList: [], // 商品下拉列
      sourceList: Object.values(GOODS_SOURCE_ENUM).filter(
        (item) => item.value !== GOODS_SOURCE_ENUM.ALL.value
      ), // 位置下拉（过滤掉全部）
      formData: {
        sku_codes_file:'',//文件链接
        source: "", // 位置
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      businessData: {}, // 当前商品的集配中心信息
      formRules: {
        sku_codes_file: [
          { required: true, message: "请上传文件", trigger: "change" },
        ],
        source: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择位置",
          },
        ],
      },
    };
  },
  methods: {
    openfile(){
      this.download('https://jiunong-purchase.oss-cn-beijing.aliyuncs.com/general/sku/skuSortTemplate.xlsx','商品置顶模版')
    },
    download(url1,fileName) {
      let self = this;
      const url = url1;
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function() {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
        /**
     * 上传操作
     */
     successFile(e){
      this.formData.sku_codes_file = e.data;
     },
     errorFile(e){
      this.formData.sku_codes_file=''
      this.$message({
                type: "error",
                message: "上传失败",
              });

     },
     beforeRemove(file, fileList) {
      this.formData.sku_codes_file = "";
    },
    /**
     * 初始化
     */
    async onInitData() {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
    },
    /**
     *获取商品列表
     */
    async ajaxGetProductList(val) {
      try {
        const res = await postProductSelectSearch({
          product_name_or_sku_code: val,
        });
        this.goodsSelectList = res.data;
      } catch (err) {
        console.log("ajax postProductSelectSearch err", err);
      } finally {
        this.loadingSelect = false;
      }
    },
    /**
     * 选中后展示集配中心
     */
    onChange(data) {
      if (!data) {
        this.businessData = {};
        return;
      }
      const find = this.goodsSelectList.find((child) => child.skuCode === data);
      // console.log("🆒 onHandleSelect", this.goodsSelectList, find);
      this.businessData = find.business;
    },
    /**
     *下拉框远程搜索
     */
    onRemoteMethod(query) {
      if (query !== "") {
        this.loadingSelect = true;
        this.ajaxGetProductList(query);
      } else {
        this.goodsSelectList = [];
      }
    },
    /**
     * 下拉框出现/隐藏时触发
     */
    onChangeVisible(e) {
      if (!e) {
        this.goodsSelectList = [];
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.ajaxpostsortbatchadd();
      });
    },
    /**
     * 提交
     */
    async ajaxpostsortbatchadd() {
      try {
        const params = {
          ...this.formData,
        };
        params.sort = params.sort;
        await postsortbatchadd(params);
        this.$message.success("操作成功");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postsortbatchadd err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  // /deep/ .el-form {
  //   margin-left: 120px;
  // }
  .el-input,
  .el-select {
    width: 400px;
  }
}
</style>
