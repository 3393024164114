var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "businessName",
          align: "center",
          "min-width": "70",
          label: "集配中心",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "sourceName",
          align: "center",
          "min-width": "55",
          label: "区域",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "firstName",
          align: "center",
          "min-width": "100",
          label: "一级分类",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "secondName",
          align: "center",
          "min-width": "100",
          label: "二级分类",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "title",
          align: "center",
          "min-width": "120",
          label: "商品名称",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "skuCode",
          align: "center",
          "min-width": "100",
          label: "货号",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "supplierName",
          align: "center",
          "min-width": "100",
          label: "供应商",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "buyerName",
          align: "center",
          "min-width": "55",
          label: "采购",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "kv",
          align: "center",
          label: "图片",
          "min-width": "55",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.kv
                  ? _c("el-image", {
                      staticStyle: { width: "30px", height: "30px" },
                      attrs: {
                        src: scope.row.kv,
                        "preview-src-list": [scope.row.kv],
                      },
                    })
                  : _c("span", [_vm._v("-")]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "stock",
          align: "center",
          "min-width": "55",
          label: "库存",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "sort",
          align: "center",
          label: "排序",
          "min-width": "55",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "stateName",
          align: "center",
          "min-width": "60",
          label: "商品状态",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "createdAt",
          align: "center",
          "min-width": "130",
          label: "添加时间",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "opName",
          align: "center",
          "min-width": "55",
          label: "添加人",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "statusName",
          align: "center",
          "min-width": "55",
          label: "置顶状态",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "purchase_deduction_amount",
          align: "center",
          label: "操作",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleDelete(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 删除 ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }