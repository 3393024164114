<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      prop="businessName"
      align="center"
      min-width="70"
      label="集配中心"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="sourceName"
      align="center"
      min-width="55"
      label="区域"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="firstName"
      align="center"
      min-width="100"
      label="一级分类"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="secondName"
      align="center"
      min-width="100"
      label="二级分类"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="title"
      align="center"
      min-width="120"
      label="商品名称"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="skuCode"
      align="center"
      min-width="100"
      label="货号"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="supplierName"
      align="center"
      min-width="100"
      label="供应商"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="buyerName"
      align="center"
      min-width="55"
      label="采购"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="kv" align="center" label="图片" min-width="55">
      <template slot-scope="scope">
        <el-image
          v-if="scope.row.kv"
          style="width: 30px; height: 30px"
          :src="scope.row.kv"
          :preview-src-list="[scope.row.kv]"
        ></el-image>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="stock"
      align="center"
      min-width="55"
      label="库存"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="sort" align="center" label="排序" min-width="55">
    </el-table-column>
    <el-table-column
      prop="stateName"
      align="center"
      min-width="60"
      label="商品状态"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="createdAt"
      align="center"
      min-width="130"
      label="添加时间"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="opName"
      align="center"
      min-width="55"
      label="添加人"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column
      prop="statusName"
      align="center"
      min-width="55"
      label="置顶状态"
      show-overflow-tooltip
    >
    </el-table-column>
    
    
    <el-table-column
      prop="purchase_deduction_amount"
      align="center"
      label="操作"
    >
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleDelete(scope.row)">
          删除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { postProductSortListRemove } from "@/api/generalControl/config/index";

export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 删除
     */
    onHandleDelete(row) {
      this.$confirm("确定删除这条数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        customClass: "jn-confirm-message-style",
        iconClass: "el-icon-warning",
      }).then(async () => {
        const params = {
          sku_code: row.skuCode,
          source: row.source,
        };
        try {
          await postProductSortListRemove(params);
          this.$message.success("已删除");
          this.$emit("on-change");
        } catch (error) {}
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
