var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.titleDialog,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: {
                model: _vm.formData,
                rules: _vm.formRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "添加商品：", prop: "sku_code" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "输入商品名称/货号",
                        loading: _vm.loadingSelect,
                        "remote-method": _vm.onRemoteMethod,
                        clearable: "",
                        filterable: "",
                        remote: "",
                      },
                      on: {
                        "visible-change": _vm.onChangeVisible,
                        change: _vm.onChange,
                      },
                      model: {
                        value: _vm.formData.sku_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "sku_code", $$v)
                        },
                        expression: "formData.sku_code",
                      },
                    },
                    _vm._l(_vm.goodsSelectList, function (item) {
                      return _c("el-option", {
                        key: item.skuCode,
                        attrs: {
                          label: `${item.title}【${item.skuCode}】【${
                            item?.supplier?.title || ""
                          }】`,
                          value: item.skuCode,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.businessData.name
                ? _c(
                    "el-form-item",
                    { attrs: { label: "集配中心：" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.businessData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.businessData, "name", $$v)
                          },
                          expression: "businessData.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "排序：", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入排序", clearable: "" },
                    model: {
                      value: _vm.formData.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sort", $$v)
                      },
                      expression: "formData.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "位置：", prop: "source" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "选择位置", clearable: "" },
                      model: {
                        value: _vm.formData.source,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "source", $$v)
                        },
                        expression: "formData.source",
                      },
                    },
                    _vm._l(_vm.sourceList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }