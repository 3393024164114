import axios from "@/api/http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-基础设置-运营配置列表
 * @param {*} params
 * @returns
 */
export function getProductSortList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/sort/list`,
    data: params,
  });
}

/**
 * 总控-基础设置-运营配置列表-店铺下拉
 * @param {*} params
 * @returns
 */
export function postProductSelectSearch(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/search`,
    data: params,
  });
}

/**
 * 总控-基础设置-运营配置列表-新增
 * @param {*} params
 * @returns
 */
export function postProductSortListCreate(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/sort/add`,
    data: params,
  });
}

/**
 * 总控-基础设置-运营配置列表-删除
 * @param {*} params
 * @returns
 */
export function postProductSortListRemove(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/sort/delete`,
    data: params,
  });
}
/**
 * 总控-商品管理商品置顶筛选条件
 * @param {*} params
 * @returns
 */
export function postProductsearchdata(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/sort/search/data`,
    data: params,
  });
}
/**
 * 总控-批量新增商品
 * @param {*} params
 * @returns
 */
export function postsortbatchadd(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/product/sort/batch/add`,
    data: params,
  });
}

/**
 * 原来基础设置-改商品管理商品置顶
 * @param {*} params
 * @returns
 */
export function getsortlistexport(params = {}) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/product/sort/list/export`,
    responseType: "blob",
    params,
  });
}
